<template>
  <div>
    <Navbar @finished="navLoaded = true" />
    <NuxtPage />
    <Footer />
    <CookieBanner v-if="gtag" :gtag="gtag" />
    <Toasts />
    <CSSEditorPanel v-if="navLoaded && $config.public.devMode && isCreativeMode()" />
    <ThemePicker v-if="navLoaded && $config.public.devMode && isCreativeMode()" />
  </div>
</template>
<script setup lang="ts">
import { createClient } from '@sanity/client'

const navLoaded = ref(false)
const query = groq`*[_type == 'settings'][0]{
  'googleGA': keys.googleGA
}`
const sanity = createClient(useSanity().config)

// get initial page data
const { data, error } = await useAsyncData(async () => {
  return await sanity.fetch<{googleGA: string}>(query)
})

const gtag = ref(data.value.googleGA)
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>
