export const inlineImages = `
_type == 'inlineImages' || _type == 'blockContentInlineImages' => {
  ...,
  images[] {
    ...,
    'image': asset->url + '?auto=format',
    'alt': alt
  }
}
`
