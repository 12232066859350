/** @type {import('tailwindcss').Config} */

import * as config from './base/tailwind.config.js'

export const content = [
  ...config.content
  // ADD Additional content paths here
]
export const colors = {
  ...config.colors
  // ADD Additional colors here
}
export const theme = {
  ...config.theme
  // ADD Additional config here
}
export const plugins = [
  ...config.plugins
  // ADD Additional plugins here
]
