import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
const createToast = (title: string, description: string, status: TToastStatus): IToast => ({
  title,
  description,
  status,
  id: Math.random() * 1000
})

export const useStatesStore = defineStore('states', () => {
  const toasts = ref<IToast[]>([])
  const componentSelected = ref<string>('')
  const editPanelShowing = ref(false)
  const transparentEffect = ref(false)
  const isEditPanelShowing = computed(() => editPanelShowing.value)
  const componentSelectionClasses = computed(() => {
    return isCreativeMode() && ((editPanelShowing.value && !componentSelected.value) || (!editPanelShowing.value && componentSelected.value) || (!editPanelShowing.value && !componentSelected.value))
  })

  function useToast (payload: ToastPayload, status: TToastStatus) {
    const { title, description, timeout } = payload
    const toast = createToast(title, description, status)
    toasts.value.push(toast)

    setTimeout(() => removeToast(toast.id), timeout ?? 2000)
  }

  function removeToast (id: number) {
    toasts.value = toasts.value.filter(t => t.id !== id)
  }

  const createModeAndEditPanelShowing = (componentName?: string) => computed(() => isCreativeMode() && componentSelected.value !== componentName)
  function setComponentSelected (component: string) {
    componentSelected.value = component
  }
  function setTransparentEffect (val: boolean) {
    transparentEffect.value = val
  }
  function toggleEditPanel () {
    editPanelShowing.value = !editPanelShowing.value
    if (!editPanelShowing.value) { componentSelected.value = '' }
  }
  watch(
    () => componentSelected.value,
    (val) => {
      if (val && !editPanelShowing.value) {
        editPanelShowing.value = true
      }
    }
  )

  return { toasts, useToast, removeToast, componentSelected, editPanelShowing, transparentEffect, setTransparentEffect, isEditPanelShowing, componentSelectionClasses, createModeAndEditPanelShowing, toggleEditPanel, setComponentSelected }
})
