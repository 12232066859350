<!-- eslint-disable tailwindcss/migration-from-tailwind-2 -->
<template>
  <nav
    v-if="navigation"
    id="desktop-menu-con"
    ref="nav"
    aria-label="desktop-menu"
    :style="[transparentEffect && navScrollPercentage === 0 ? '--tw-bg-opacity:0; --tw-border-opacity:0;' : !transparentEffect && navScrollPercentage === 0 ? '--tw-bg-opacity:1; --tw-border-opacity:1;' : '']"
    :class="[
      'bg-nav transition-duration-300 border-secondary text-nav-text top-0 z-[99] flex w-full flex-nowrap items-center justify-between border-b border-solid p-4 text-[12px] leading-[12px] transition-all',
      {'fixed': transparentEffect},
      {'sticky': !transparentEffect},
    ]"
  >
    <div class="max-wrapper flex flex-nowrap items-center justify-between !p-0">
      <div v-if="logo.url || logo.text" id="logo">
        <NuxtLink
          class="flex flex-nowrap items-center justify-center gap-x-4"
          :to="logo.link"
        >
          <img
            v-if="logo.url"
            alt="logo"
            height="55px"
            :src="logo.url"
            class="mr-2 max-h-[55px] w-auto"
          >
          <span
            v-if="logo.text"
            class="text-nav-text font-headerBold text-2xl"
          >
            {{ logo.text }}
          </span>
        </NuxtLink>
      </div>

      <div id="right" class="flex flex-nowrap items-center">
        <div id="desktop-menu" class="hidden lg:block">
          <div v-if="navBarItems" class="menu-items flex flex-nowrap items-center">
            <div v-for="(item, index) in navBarItems" :key="index" class="nav-link text-secondary tracking-[1.75px]">
              <NuxtLink v-if="!item.items" :to="item.to" class="hover:!text-secondary font-main !text-nav-text p-[1em] font-bold uppercase hover:no-underline">
                {{ item.label }}
              </NuxtLink>

              <div v-if="item.items" class="sub-menu hover:!text-green !text-nav-text group relative hover:no-underline">
                <span class="font-main p-[1em] font-bold uppercase">
                  {{ item.label }}
                </span>
                <div class="sub-menu-items absolute top-[24px] z-10 hidden rounded-[5px] bg-white p-2 tracking-wider text-black group-hover:block">
                  <div v-for="(subItem, subIndex) in item.items" :key="subIndex" class="nav-link mb-[3px] flex last:mb-0">
                    <NuxtLink v-if="!subItem.items" :to="subItem.to" active-class="!bg-primary !text-primary-text" class="w-full whitespace-nowrap rounded-[5px] p-4">
                      {{ subItem.label }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </div>
            <POButton
              v-if="navBarBtn"
              :id="navBarBtn?.gaTag"
              :class="['relative ml-4', {'!text-nav-text': navScrollPercentage < 0.5}]"
              style="--tw-bg-opacity: 1"
              color="primary"
              btn-style="normal"
              :link="navBarBtn?.[navBarBtn?.linkType]"
            >
              {{ navBarBtn?.text }}
            </POButton>
          </div>
        </div>

        <div id="toggle-btn" class="z-10 ml-8 block sm:ml-0 lg:hidden" @click="toggleMenu({close: false})">
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
      </div>
    </div>
  </nav>
  <Transition name="slide-in-from-left">
    <nav v-if="showMobileMenu" aria-label="mobile-menu" class="fixed top-0 z-[200] w-full">
      <div id="mobile-menu" ref="mobileNav" class="bg-nav sticky top-0 h-dvh w-full max-w-[300px] overflow-hidden lg:hidden">
        <header class="flex items-center justify-end">
          <button class="border-0 border-transparent bg-transparent p-[10px]" @click="toggleMenu({close: true})">
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="3.5"
              stroke="currentColor"
              class="size-6"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </header>
        <div v-if="navBarItems" :class="['menu-items max-h-[calc(100%-(1.5rem+20px))] px-4 duration-500', {'translate-x-[calc(-100%-4rem)]': mobileSubMenuToBeShown}, {'overflow-y-auto': showScroll}]">
          <div v-for="(item, index) in navBarItems" :key="index" class="nav-link w-full p-4 text-white">
            <NuxtLink v-if="!item.items" :to="item.to" active-class="!text-accent" class="hover:!text-accent !text-white hover:no-underline" @click="closeMenus()">
              {{ item.label }}
            </NuxtLink>

            <div v-if="item.items" class="sub-menu group w-full hover:no-underline">
              <div class="flex items-center justify-between" @click="toggleMobileSubMenu(item.label)">
                <span class="font-main">{{ item.label }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </div>
              <Transition name="mobile-sub-menu-slide" @after-leave="toggleScroll" @enter="toggleScroll">
                <div
                  v-if="mobileSubMenuToBeShown === item.label"
                  :data-sub-menu="item.label"
                  class="mobile-sub-menu-items !text-background absolute top-0 w-full translate-x-[calc(100%+2rem)] bg-white p-4 tracking-wider"
                >
                  <header class="border-dark flex items-center justify-start border-b py-4 pt-2" @click="toggleMobileSubMenu(item.label)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="mr-2 size-6"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    <span class="font-main">{{ item.label }}</span>
                  </header>
                  <div v-for="(subItem, subIndex) in item.items" :key="subIndex" class="nav-link mb-[3px] flex last:mb-0">
                    <NuxtLink v-if="!subItem.items" :to="subItem.to" active-class="!text-accent" class="w-full whitespace-nowrap p-4" @click="closeMenus()">
                      {{ subItem.label }}
                    </NuxtLink>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 w-full p-8">
          <POButton
            v-if="navBarBtn"
            :id="navBarBtn?.gaTag"
            class="relative"
            color="primary"
            btn-style="normal"
            :link="navBarBtn[navBarBtn.linkType]"
          >
            {{ navBarBtn.text }}
          </POButton>
        </div>
      </div>
    </nav>
  </Transition>
  <Transition name="fade">
    <div v-if="showMobileMenu" id="mobile-overlay" class="bg-overlay fixed left-0 top-0 z-[100] size-full cursor-all-scroll lg:hidden" />
  </Transition>
</template>
<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { cta } from '../component_querys'
import { theme } from '../../tailwind.config.js'

const router = useRoute()

const nav = ref(null)
const mobileNav = ref(null)

const hasFullSizeHero = ref(true)
const navScrollPercentage = ref(0)
const showMobileMenu = ref(false)
const mobileSubMenuToBeShown = ref()
const showScroll = ref(true) /* this is needed for the root nav to have scroll when needed but still have the sub menus still work */

const query = groq`
{
  ...*[_type == "settings"][0] {
    'themeVars': theme,
    'seo': seo {
      ogTitle,
      ogDescription,
      'ogImage': ogImage.asset->url + '?auto=format&w=1200&h=630',
      twitterCard,
    }
  },
  ...*[_type == "navigation"][0] {
    'transparentEffect': transparentEffect,
    'logo': {
      'url': logo.asset->url + '?auto=format&w=400',
      'text': logo.text,
      'link': select(
        logo.link->slug.current == '/' => logo.link->slug.current,
        logo.link->slug.current != '/' => '/'+logo.link->slug.current,
      ),
    },
    'menuItems': menuItems[]->{
      'label': title,
      'to': select(
        slug.current == '/' => slug.current,
        slug.current != '/' => '/'+slug.current,
      ),
      'items': group[]->{
        'label': title,
        'to': select(
          slug.current == '/' => slug.current,
          slug.current != '/' => '/'+slug.current,
        ),
        'items': group[]->{
          'label': title,
          'to': select(
            slug.current == '/' => slug.current,
            slug.current != '/' => '/'+slug.current,
          ),
        }
      }
    },
    ${cta}
  }
}
`

interface Settings {
  themeVars: string;
  seo: SEOData;
}

interface MenuItem {
  label: string;
  to: string;
  items?: MenuItem[];
}
interface Navigation {
  transparentEffect?: boolean;
  logo: {
    url: string;
    text?: string;
    link: string;
  };
  menuItems: MenuItem[];
  cta?: CTA
}
const { data } = await useAsyncData(async () => {
  return await useSanity().fetch<Settings & Navigation>(query)
})

if (!data?.value) { throw createError({ statusCode: 404, fatal: true }) }

const { themeVars, seo: seoData } = data.value
const { transparentEffect, logo, menuItems: navBarItems, cta: navBarBtn } = data.value
const navigation = { transparentEffect, logo, navBarItems, navBarBtn }

const store = useStatesStore()
const { setTransparentEffect } = store

setTransparentEffect(!!transparentEffect)

if (themeVars) {
  useHead({
    bodyAttrs: {
      style: themeVars
    }
  })
}
const emit = defineEmits(['finished'])
emit('finished')

setPageSEO(seoData)

function closeMenus () {
  toggleMobileSubMenu('close')
  toggleMenu({ close: true })
  showScroll.value = true
}

onClickOutside(mobileNav, () => {
  closeMenus()
})

function toggleScroll () {
  showScroll.value = !showScroll.value
}

function toggleMenu ({ close }: {close?: boolean}) {
  if (!import.meta.browser) { return }
  const navBar = nav.value as unknown as HTMLElement
  if (!navBar) { return }
  const currentWidth = navBar.clientWidth
  const desktopBreakPoint = pxToInt(theme.extend.screens.lg)

  const isMobile = currentWidth < desktopBreakPoint

  const body = document.querySelector('body')
  if (showMobileMenu.value) {
    body?.classList.remove('no-scroll')
  } else {
    body?.classList.add('no-scroll')
  }
  if (close) {
    showMobileMenu.value = false
    body?.classList.remove('no-scroll')
    return
  }
  if (isMobile) { showMobileMenu.value = !showMobileMenu.value }
}

function toggleMobileSubMenu (label: string) {
  if (label === 'close') {
    mobileSubMenuToBeShown.value = null
    return
  }
  mobileSubMenuToBeShown.value = mobileSubMenuToBeShown.value === label ? null : label
}

function updateNav () {
  if (!nav.value) { return }
  if (!transparentEffect) {
    (nav.value as HTMLElement).style.setProperty('--tw-bg-opacity', '1');
    (nav.value as HTMLElement).style.setProperty('--tw-border-opacity', '1');
    (nav.value as HTMLElement).style.setProperty('position', 'sticky')
    return
  }

  hasFullSizeHero.value = !!document.querySelector('nav#desktop-menu-con + .page-wrapper > .hero img:first-child') || !!document.querySelector('nav#desktop-menu-con + .page-wrapper > .hero video[data-fullscreen="true"]')
  if (!hasFullSizeHero.value) {
    (nav.value as HTMLElement).style.setProperty('position', 'sticky')
    return
  }
  const navHeight = (nav.value as HTMLElement).offsetHeight
  navScrollPercentage.value = Math.round((window.scrollY / navHeight) * 100) / 100

  const opacity = navScrollPercentage.value < 1 ? navScrollPercentage.value.toString() : '1';

  (nav.value as HTMLElement).style.setProperty('--tw-bg-opacity', opacity);
  (nav.value as HTMLElement).style.setProperty('--tw-border-opacity', opacity);
  (nav.value as HTMLElement).style.setProperty('position', 'fixed')
}

watch(() => router.params.slug, () => closeMenus())

onMounted(() => {
  if (!import.meta.browser) { return }
  window.addEventListener('resize', closeMenus)
  if (transparentEffect) {
    if (!import.meta.browser) { return }
    document.addEventListener('scroll', updateNav)
  }
})

</script>
<style lang="scss" scoped>
.nav-link {
  a { text-decoration: none; white-space: nowrap; }
}
.sub-menu-items {
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    display: block;
    top: -10px;
    left: 2em;
    border-bottom: 10px solid #FFF;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  .nav-link {
    a {
      font-size: 1rem;
      @apply text-black;
      &:hover {
        @apply bg-nav;
        @apply text-nav-text;
      }
    }
  }
}
.mobile-sub-menu-items {
  .nav-link {
    a {
      font-size: 1rem;
      @apply text-black;
      &:hover {
        @apply bg-nav;
        @apply text-nav-text;
      }
    }
  }
}
</style>
