/** @type {import('tailwindcss').Config} */

const getColor = (colorVar, { opacityVariable, opacityValue }) => {
  if (opacityValue !== undefined) {
    return `rgb(var(${colorVar})/${opacityValue})`
  }
  if (opacityVariable !== undefined) {
    return `rgb(var(${colorVar})/var(${opacityVariable}, 1))`
  }

  return `rgb(var(${colorVar}))`
}

export const content = [
  './components/*.{js,vue,ts}',
  './components/**/*.{js,vue,ts}',
  './components/**/**/*.{js,vue,ts}',
  './components/**/**/**/*.{js,vue,ts}',
  './layouts/**/*.vue',
  './pages/**/*.vue',
  './*.vue',
  './plugins/**/*.{js,ts}',
  './nuxt.config.{js,ts}',
  './base/components/*.{js,vue,ts}',
  './base/components/**/*.{js,vue,ts}',
  './base/components/**/**/*.{js,vue,ts}',
  './base/components/**/**/**/*.{js,vue,ts}',
  './base/layouts/**/*.vue',
  './base/pages/**/*.vue'
]

function getColors (type) {
  let varname = '--tw-bg-opacity'
  if (type === 'text') { varname = '--tw-text-opacity' }
  if (type === 'background') { varname = '--tw-bg-opacity' }
  if (type === 'border') { varname = '--tw-border-opacity' }

  const colorNames = ['main', 'text', 'background', 'primary', 'secondary', 'accent']
  const mainColorNames = ['nav', 'background', 'primary', 'secondary', 'accent']
  const shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]

  const colors = {}
  colorNames.forEach((name) => {
    if (mainColorNames.includes(name)) {
      mainColorNames.forEach((varName) => {
        colors[varName] = {
          DEFAULT: params => getColor(`--${varName}`, { opacityVariable: varname }),
          text: params => getColor(`--${varName}-text`, { opacityVariable: varname })
        }
        shades.forEach((shade) => {
          colors[varName][shade] = params => getColor(`--${varName}-${shade}`, { opacityVariable: varname })
          colors[varName][`${shade}-text`] = params => getColor(`--${varName}-${shade}-text`, { opacityVariable: varname })
        })
      })
    } else {
      colors[name] = {
        DEFAULT: params => getColor(`--${name}`, { opacityVariable: varname })
      }
      shades.forEach((shade) => {
        colors[name][shade] = params => getColor(`--${name}-${shade}`, { opacityVariable: varname })
      })
    }
  })
  return colors
}

const gridValues = {
  '1/2': '50%',
  '1/3': '33.333333%',
  '2/3': '66.666667%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '1/6': '16.666667%',
  '2/6': '33.333333%',
  '3/6': '50%',
  '4/6': '66.666667%',
  '5/6': '83.333333%',
  '1/12': '8.333333333333333%',
  '2/12': '16.666666666666666%',
  '3/12': '25%',
  '4/12': '33.33333333333333%',
  '5/12': '41.66666666666667%',
  '6/12': '50%',
  '7/12': '58.33333333333334%',
  '8/12': '66.66666666666666%',
  '9/12': '75%',
  '10/12': '83.33333333333334%',
  '11/12': '91.66666666666666%'
}
function getGridValuesWithGapCorrection (theme) {
  const spacing = theme('gap')
  const size = gridValues

  // Filter percentage sizes and generate custom flex-basis values
  const percentageSizes = Object.keys(size).filter(key => key.includes('/'))

  // Map over percentage sizes and spacing to generate CSS custom properties
  const flexBasisValues = percentageSizes.flatMap(sizeKey =>
    Object.entries(spacing).map(([spacingKey, spacingValue]) => {
      const [numerator, denominator] = sizeKey.split('/').map(Number)
      const calcValue = `calc((100% - ${spacingValue}) / (${denominator} / ${numerator}))`
      return [`${sizeKey}-calc-${spacingKey}`, calcValue]
    })
  )
  return Object.fromEntries(flexBasisValues)
}

export const colors = { ...getColors('text') }
export const theme = {
  fontFamily: {
    main: ['"Montserrat", Arial, Helvetica, sans-serif'],
    headerThin: ['"Poppins-Thin", Arial, Helvetica, sans-serif'],
    headerRegular: ['"Poppins-Regular", Arial, Helvetica, sans-serif'],
    headerBold: ['"Poppins-Bold", Arial, Helvetica, sans-serif']
  },
  extend: {
    screens: {
      xs: '400px',
      sm: '600px',
      md: '960px',
      lg: '1200px',
      xl: '1440px',
      '2xl': '1600px'
    },
    colors: {
      ...getColors('text')
    },
    backgroundColor: {
      ...getColors('background'),
      overlay: '#000000bf'
    },
    borderColor: {
      ...getColors('border')
    },
    boxShadow: {
      main: '1px 1px 4px 0px rgba(0, 0, 0, 0.3)',
      light: '0 6px 6px rgba(0,0,0,.04)'
    },
    padding: gridValues,
    margin: gridValues,
    width: ({ theme }) => ({
      ...gridValues,
      ...getGridValuesWithGapCorrection(theme)
    }),
    maxWidth: gridValues,
    flexBasis: ({ theme }) => getGridValuesWithGapCorrection(theme)
  }
}

export const plugins = []
