<template>
  <div class="flex items-center justify-between">
    <label class="text-sm">{{ label }}</label>
    <input
      type="color"
      class="size-10 rounded border border-transparent bg-transparent p-0 hover:cursor-pointer"
      :value="modelValue"
      @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    >
  </div>
</template>

<script setup lang="ts">
defineProps<{
  label: string
  modelValue: string
}>()

const emit = defineEmits(['update:modelValue'])
</script>
