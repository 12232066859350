<!-- eslint-disable tailwindcss/migration-from-tailwind-2 -->
<template>
  <div class="flex flex-col">
    <label class="mb-1 text-sm">{{ label }}</label>
    <select
      class="border-background-text bg-background-text text-background-text rounded border border-opacity-30 bg-opacity-10 p-2"
      :value="modelValue"
      @change="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    >
      <option v-for="option in options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  label: string
  options: string[]
  modelValue: string
}>()

const emit = defineEmits(['update:modelValue'])
</script>
