<template>
  <div class="border-background-text w-full overflow-hidden rounded-md border">
    <button
      class="bg-muted/50 hover:bg-muted/70 flex w-full items-center justify-between p-2 transition-colors"
      @click="emit('toggle')"
    >
      <span class="font-semibold">{{ title }}</span>
      <component :is="isOpen ? ChevronUp : ChevronDown" class="size-4" />
    </button>
    <div v-if="isOpen" class="space-y-2 p-2">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import ChevronDown from 'lucide-vue-next/dist/esm/icons/chevrons-down'
import ChevronUp from 'lucide-vue-next/dist/esm/icons/chevrons-up'

defineProps<{
  title: string
  isOpen: boolean
}>()

const emit = defineEmits(['toggle'])
</script>
