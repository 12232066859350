<script setup lang="ts">
import type { BlockContentCTA } from '../../../utils'

const props = defineProps<BlockContentCTA>()
const link = computed(() => props.cta[props.cta.linkType])
</script>

<template>
  <div
    :class="[
      'flex',
      {'justify-start': position === 'left'},
      {'justify-center': position === 'center'},
      {'justify-end': position === 'right'}
    ]"
  >
    <POButton
      v-if="props.cta.linkType"
      :btn-style="props.cta.style"
      :color="props.cta.color"
      :uppercase="true"
      :link="link"
    >
      {{ props.cta.text }}
    </POButton>
  </div>
</template>
