<template>
  <li
    :id="id.toString()"
    :class="[
      `group pointer-events-auto
      relative flex w-full items-center
      justify-between space-x-4 overflow-hidden
      rounded-md border
      p-6 pr-8 shadow-lg transition-all`,
      {'border-white bg-green-600/90 text-white': status === 'success'},
      {'border-white bg-yellow-300/90 text-white': status === 'warning'},
      {'border-white bg-red-500/90 text-white': status === 'error'},
    ]"
    :title="title"
    :description="description"
    style="user-select: none; touch-action: none;"
  >
    <div class="grid gap-1">
      <div class="font-semibold">
        {{ title }}
      </div>
      <div class="opacity-90">
        {{ description }}
      </div>
      <button
        type="button"
        class="absolute right-2 top-2 rounded-md p-1 text-white/70 opacity-0 transition-opacity hover:text-white focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100"
        @click.stop="removeToast(id)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-xicon size-4"
        ><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
      </button>
    </div>
  </li>
</template>
<script setup lang="ts">
import type { IToast } from '../../../types/toasts'
const states = useStatesStore()
const { removeToast } = states
defineProps<IToast>()
</script>
