<!-- eslint-disable tailwindcss/migration-from-tailwind-2 -->
<template>
  <div class="flex flex-col">
    <label class="mb-1 text-sm">{{ label }}</label>
    <input
      type="text"
      class="border-background-text bg-background-text text-background-text rounded border border-opacity-30 bg-opacity-10 p-2"
      :placeholder="placeholder"
      :value="modelValue"
      @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    >
  </div>
</template>

<script setup lang="ts">
defineProps<{
  label: string
  placeholder?: string
  modelValue: string
}>()

const emit = defineEmits(['update:modelValue'])
</script>
