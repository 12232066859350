<template>
  <div class="edit-component-styles">
    <div
      :class="[`
        bg-primary text-primary-text hover:bg-accent hover:text-accent-text fixed
        right-0 top-0 z-[999998] m-4 flex size-[50px]
        items-center justify-center
        rounded-full p-4 transition-all duration-300 hover:cursor-pointer
      `]"
      @click="toggleEditPanel()"
    >
      <PaletteIcon class="text-primary-text hover:cursor-pointer" />
    </div>
  </div>
  <Transition name="slide-in-from-right">
    <div v-if="isEditPanelShowing" class="bg-background border-secondary fixed right-0 top-0 z-[999999] h-screen w-80 space-y-2 overflow-y-auto border-l-2 p-4">
      <div class="relative flex h-full flex-col flex-wrap justify-between">
        <div class="main-con flex flex-col justify-start gap-y-4">
          <div class="header w-full">
            <ChevronLast class="text-background-text relative right-0 top-0 mb-2 opacity-40 hover:cursor-pointer" @click="toggleEditPanel()" />
            <span class="w-full text-left text-xl font-bold">
              {{ componentSelected ? `${capitalize(componentSelected.replaceAll('-', ' '))} Styles` : 'Style Editor' }}
            </span>
          </div>

          <Section title="Border" :is-open="sections.border" @toggle="toggleSection('border')">
            <ColorInput v-model="styles['border-color']" label="Color" />
          </Section>

          <Section title="Color" :is-open="sections.color" @toggle="toggleSection('color')">
            <ColorInput v-model="styles.bg" label="Background Color" />
            <ColorInput v-model="styles.text" label="Text Color" />
          </Section>

          <Section title="Clip Path" :is-open="sections.clipPath" @toggle="toggleSection('clipPath')">
            <Select
              v-model="selectedClipPathPreset"
              label="Preset Shapes"
              :options="['Custom', ...Object.keys(clipPathPresets)]"
              @update:model-value="updateClipPath"
            />
            <TextInput
              v-model="styles['clip-path']"
              label="Value"
              placeholder="e.g., circle(50% at 50% 50%)"
            />
          </Section>
        </div>

        <div
          :class="[`
            relative flex h-[50px] w-full items-center
            justify-center gap-2 rounded-lg border border-solid
            border-green-700 bg-green-500 p-4 text-white transition-all duration-300
            hover:cursor-pointer hover:bg-green-600 hover:text-white
          `]"
          @click="publishChanges"
        >
          <Save class="hover:cursor-pointer" color="white" />
          <label class="w-fit hover:cursor-pointer">Publish Changes</label>
        </div>

        <div
          :class="[`
            bg-primary text-primary-text border-accent hover:bg-primary-200 hover:text-primary-200-text
            relative flex h-[50px] w-full items-center
            justify-center gap-2 rounded-lg border border-solid p-4
            transition-all duration-300 hover:cursor-pointer
          `]"
          @click="resetToDefaults"
        >
          <RefreshCcw class="hover:cursor-pointer" color="white" />
          <label class="w-fit hover:cursor-pointer">Reset To Defaults</label>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, reactive, capitalize } from 'vue'
import ChevronLast from 'lucide-vue-next/dist/esm/icons/chevron-last'
import PaletteIcon from 'lucide-vue-next/dist/esm/icons/palette'
import RefreshCcw from 'lucide-vue-next/dist/esm/icons/refresh-ccw'
import Save from 'lucide-vue-next/dist/esm/icons/save'
import { createClient, Patch } from '@sanity/client'
import Section from './Section.vue'
import ColorInput from './ColorInput.vue'
import TextInput from './TextInput.vue'
import Select from './Select.vue'

const states = useStatesStore()
const { componentSelected, isEditPanelShowing } = storeToRefs(states)
const { toggleEditPanel, setComponentSelected, useToast } = states

type ClipPathPresetKeys = 'Corners' | 'Circle' | 'Triangle' | 'Pentagon' | 'Hexagon' | 'Star' | 'Message Bubble' | 'Custom'
type StyleKeys = 'border-color' | 'bg' | 'text' | 'clip-path'
interface Styles {
  'border-color': string;
  bg: string;
  text: string;
  'clip-path': string;
}

const sections = reactive({
  border: true,
  color: true,
  clipPath: true
})

const styles = reactive<Styles>({
  'border-color': '#000000',
  bg: '#3b82f6',
  text: '#ffffff',
  'clip-path': 'inset(0 0 0 0 round 0 1rem 0 1rem)'
})

const defaultStyles = reactive<Styles>({
  'border-color': '#000000',
  bg: '#3b82f6',
  text: '#ffffff',
  'clip-path': 'inset(0 0 0 0 round 0 1rem 0 1rem)'
})

const clipPathPresets = {
  Corners: 'inset(0 0 0 0 round 0 1rem 0 1rem)',
  Circle: 'circle(50% at 50% 50%)',
  Triangle: 'polygon(50% 0%, 0% 100%, 100% 100%)',
  Pentagon: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
  Hexagon: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
  Star: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
  'Message Bubble': 'polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)'
}

const selectedClipPathPreset = ref('Custom')

const toggleSection = (section: keyof typeof sections) => {
  sections[section] = !sections[section]
}

const updateClipPath = (value: ClipPathPresetKeys) => {
  if (value !== 'Custom') {
    styles['clip-path'] = clipPathPresets[value]
  }
}

watch(
  () => styles,
  (val) => {
    if (!componentSelected.value) { return }
    Object.entries(val).forEach((item) => {
      const [key, value] = item
      document.body.style.setProperty(`--${componentSelected.value}-${key}`, key === 'clip-path' ? value : hexToRgb(value))
    })
    const storedColors = { cssvars: getCssVars()?.split(';\n').join(';') }
    setItem('cssvars', storedColors)
  },
  { deep: true }
)

function resetToDefaults () {
  const reset = confirm('are you sure you want to reset to defaults?')
  if (!reset) { return }
  Object.keys(defaultStyles).forEach((key) => {
    styles[key as StyleKeys] = defaultStyles[key as StyleKeys]
  })
}
async function publishChanges () {
  useSanity().config.perspective = 'previewDrafts'
  const sanity = createClient(useSanity().config)

  const patch = new Patch('settings')
  const styles = document.body.getAttribute('style')
  try {
    if (styles) {
      await sanity.mutate(patch.set({ theme: styles }))
    } else {
      await sanity.mutate(patch.unset(['theme']))
    }
    toggleEditPanel()
    useToast({ title: 'Saved', description: 'changes to theme have been saved', timeout: 5000 }, 'success')
  } catch (error) {
    useToast({ title: 'Failed to Save', description: 'changes could not be saved, please try again', timeout: 5000 }, 'error')
  }
}

function getComponentNameAndColors (event:Event) {
  const style = getComputedStyle(document.body)

  function getClosestComponentName (el: HTMLElement) {
    const firstTry = el.closest('[data-componentname]') as HTMLElement
    if (firstTry) { return firstTry as HTMLElement }
    const secondTry = el.children.item(0)?.closest('[data-componentname]')
    return secondTry as HTMLElement
  }
  function getColorValueSet (styleVal: string) {
    const [r, g, b] = styleVal.split(' ').map(val => parseInt(val))
    return rgbToHex(r, g, b)
  }

  function setDefaulColorsOfComponent (name: string, defaults: Styles) {
    Object.entries(defaults).forEach((item) => {
      const [key, value] = item
      const isColor = key !== 'clip-path'

      const existingStyle = style.getPropertyValue(`--${name}-${key}`)
      const defaultStyle = style.getPropertyValue(`--${value}`)

      const styleToSet = existingStyle || defaultStyle

      if (!isColor) {
        defaultStyles[key as StyleKeys] = defaultStyle
        styles[key as StyleKeys] = styleToSet
      } else {
        defaultStyles[key as StyleKeys] = getColorValueSet(defaultStyle)
        styles[key as StyleKeys] = getColorValueSet(styleToSet)
      }
    })
  }
  const eventTarget = event.target as HTMLElement
  const elem = getClosestComponentName(eventTarget)

  if (!elem) { return }
  const name = elem.dataset?.componentname
  const defaults = elem.dataset?.defaults

  if (defaults && name) { setDefaulColorsOfComponent(name, JSON.parse(defaults) as Styles) }
  if (name) { setComponentSelected(name) }
}

onMounted(() => {
  document.body.addEventListener('click', getComponentNameAndColors)
})
onBeforeUnmount(() => {
  document.body.removeEventListener('click', getComponentNameAndColors)
})
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
