<template>
  <div class="fixed left-0 top-0 z-[200] flex h-svh w-[170px] items-center p-2">
    <div class="action-items flex h-fit w-full flex-col flex-wrap gap-8">
      <div class="pickers flex h-fit w-full flex-col flex-wrap items-start justify-start gap-2">
        <ColorPicker v-model:model-value="colors.nav" uid="nav-001" :color-names="['nav']">
          navigation
        </ColorPicker>
        <ColorPicker v-model:model-value="colors.text" uid="text-001" :color-names="['text', 'main']">
          text
        </ColorPicker>
        <ColorPicker v-model:model-value="colors.background" uid="background-001" :color-names="['background']">
          background
        </ColorPicker>
        <ColorPicker v-model:model-value="colors.primary" uid="primary-001" :color-names="['primary']">
          primary
        </ColorPicker>
        <ColorPicker v-model:model-value="colors.secondary" uid="secondary-001" :color-names="['secondary']">
          secondary
        </ColorPicker>
        <ColorPicker v-model:model-value="colors.accent" uid="accent-001" :color-names="['accent']">
          accent
        </ColorPicker>
      </div>
      <div class="relative flex h-[50px] w-full items-center justify-center gap-2 rounded-lg border-2 border-solid border-black bg-red-500 px-4 text-white transition-all duration-300 hover:cursor-pointer hover:bg-red-600 hover:text-white" @click="resetTheme">
        <RefreshCcw class="hover:cursor-pointer" color="white" />
        <label class="w-fit hover:cursor-pointer">Reset</label>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import RefreshCcw from 'lucide-vue-next/dist/esm/icons/refresh-ccw'
const style = getComputedStyle(document.body)

function rgbWrap (rgbVal: string) {
  return `rgb(${rgbVal})`
}
function resetTheme () {
  removeItem('cssvars')
  document.querySelector('body')?.removeAttribute('style')
  setColors()
}
function setColors () {
  colors.value = {
    nav: rgbWrap(style.getPropertyValue('--nav')) ?? 'rgb(255 255 255)',
    text: rgbWrap(style.getPropertyValue('--text')) ?? 'rgb(255 255 255)',
    background: rgbWrap(style.getPropertyValue('--background')) ?? 'rgb(255 255 255)',
    primary: rgbWrap(style.getPropertyValue('--primary')) ?? 'rgb(88 115 249)',
    secondary: rgbWrap(style.getPropertyValue('--secondary')) ?? 'rgb(247 247 247)',
    accent: rgbWrap(style.getPropertyValue('--accent')) ?? 'rgb(169 198 229)'
  }
}
const colors = ref({
  nav: rgbWrap(style.getPropertyValue('--nav')) ?? 'rgb(255 255 255)',
  text: rgbWrap(style.getPropertyValue('--text')) ?? 'rgb(255 255 255)',
  background: rgbWrap(style.getPropertyValue('--background')) ?? 'rgb(255 255 255)',
  primary: rgbWrap(style.getPropertyValue('--primary')) ?? 'rgb(88 115 249)',
  secondary: rgbWrap(style.getPropertyValue('--secondary')) ?? 'rgb(247 247 247)',
  accent: rgbWrap(style.getPropertyValue('--accent')) ?? 'rgb(169 198 229)'
})
onMounted(() => {
  const cssvars = getItem('cssvars')?.cssvars
  if (!cssvars) { return }
  document.querySelector('body')?.setAttribute('style', cssvars)
  setColors()
})
</script>
<style scoped lang="scss">
</style>
