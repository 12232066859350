<template>
  <PortableText
    v-if="content"
    :value="content"
    :components="myPortableTextComponents"
  />
</template>
<script setup lang="ts">
import { PortableText } from '@portabletext/vue'
import type { PortableTextComponents } from '@portabletext/vue'
import BlockContentCta from './blockContentComponents/BlockContentCta.vue'
import BlockContentInlineImages from './blockContentComponents/BlockContentInlineImages.vue'
const router = useRouter()
interface Props {
  preventStyles?: boolean;
  preventLinks?: boolean;
  content: BlockContent
}
interface ImageObj {
  align: string;
  image: string;
  alt: string;
  width: number;
  rounded: string | null;
}
interface Youtube {
  url: string
}
const props = defineProps<Props>()

function getRoundedClass (value: string | null) {
  if (!value) { return }
  return `image-rounded-${value}`
}
const myPortableTextComponents: PortableTextComponents = {
  types: {
    blockContentCta: ({ value }: { value: BlockContentCTA }) => h(BlockContentCta, {
      position: value.position,
      cta: value.cta
    }),
    blockContentInlineImages: ({ value }: { value: { images:[{image: string; alt: string;}] } }) => h(BlockContentInlineImages, {
      images: value.images
    }),
    imageObj: ({ value }: { value: ImageObj }) => h('p', {},
      h('img', { class: `image-alignment-${value.align} ${getRoundedClass(value.rounded)}`, src: value.image, alt: value.alt, style: `width: ${value.width ?? 100}%; margin-top: 1rem; margin-bottom: 1rem;` })
    ),
    youtube: ({ value }: { value: Youtube }) => h('div', { class: 'evVideo' },
      h('iframe', { width: '100%', height: '100%', loading: 'lazy', allow: 'fullscreen', src: value.url })
    )
  },
  marks: {
    textAlignment: ({ value }, { slots }: any) => {
      return h('div', { class: `w-full text-alignment-${value.alignment}` }, slots.default?.())
    },
    textColor: ({ value }, { slots }: any) => {
      return h('slot', { style: `color: ${value.hex}` }, slots.default?.())
    },
    link: ({ value }, { slots }: any) => {
      return !props.preventLinks ? h('a', { href: value.href, target: value.blank ? '_blank' : '' }, slots.default?.()) : h('span', {}, slots.default?.())
    },
    internalLink: ({ value }, { slots }: any) => {
      return !props.preventLinks
        ? h('a', {
          onClick: (e:Event) => {
            e.preventDefault()
            router.push({ path: value.slug })
          },
          href: value.slug
        }, slots.default?.())
        : h('span', {}, slots.default?.())
    }
  },
  block: {
    subtitle: (_:any, { slots }:any) => !props.preventStyles ? h('p', { class: 'subtitle' }, slots.default?.()) : h('p', {}, slots.default?.()),
    larger: (_:any, { slots }:any) => !props.preventStyles ? h('p', { class: 'larger' }, slots.default?.()) : h('p', {}, slots.default?.()),
    smaller: (_:any, { slots }:any) => !props.preventStyles ? h('p', { class: 'smaller' }, slots.default?.()) : h('p', {}, slots.default?.()),
    smallest: (_:any, { slots }:any) => !props.preventStyles ? h('p', { class: 'smallest' }, slots.default?.()) : h('p', {}, slots.default?.()),
    eye_brow: (_:any, { slots }:any) => !props.preventStyles ? h('p', { class: 'font-HeadlineBold !text-sm uppercase tracking-[2px] opacity-80' }, slots.default?.()) : h('p', {}, slots.default?.())
  },
  list: {
    bullet: (_:any, { slots }:any) => !props.preventStyles ? h('ul', { class: 'bullet-list' }, slots.default?.()) : h('ul', {}, slots.default?.()),
    number: (_:any, { slots }:any) => !props.preventStyles ? h('ol', { class: 'number-list' }, slots.default?.()) : h('ol', {}, slots.default?.())
  }
}
</script>
<style lang="scss">
.bullet-list {
  margin: 0;
  padding: 0 0 0 25px;
  list-style-type: disc;
  li {
    margin-bottom: 1rem;
    list-style: none;
    position: relative;
    &::before {
      content: "";
      background-color: #0CCDA3;
      width: 0.5em;
      height: 0.5em;
      border-radius: 1em;
      display: inline-block;
      position: absolute;
      left: -1.425em;
      top: 0.5em;
    }
  }
}

.number-list {
  margin: 0;
  padding: 0 0 0 25px;
  list-style-type: decimal;
  li {
    margin-bottom: 1rem;
    list-style: auto;
  }
}
</style>
