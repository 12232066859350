<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })

const statusCodeMessages = {
  404: {
    code: 404,
    message: 'You\'re on a treasure hunt, but there is nothing here. try one of the links above'
  },
  500: {
    code: 500,
    message: 'Something has gone wrong on our end. go back home to safety'
  }
}

const statusCode = computed(() => {
  const code: 404 | 500 | undefined = props.error?.statusCode as 404 | 500 | undefined
  return code !== undefined ? statusCodeMessages[code] : { code: 500, message: 'Something Has Gone Wrong 🤷🏼‍♂️' }
})
</script>

<template>
  <div>
    <Navbar />
    <div class="page-wrapper">
      <div class="max-wrapper flex h-[90dvh] flex-wrap items-center justify-center">
        <div class="flex w-full flex-wrap justify-center">
          <div class="flex w-full flex-wrap justify-center p-12">
            <h2 class="w-full text-center">
              {{ statusCode.code }}
            </h2>
            <h3 class="w-8/12 text-center">
              {{ statusCode.message }}
            </h3>
          </div>

          <POButton id="error-page-go-home-btn" btn-style="normal" color="opal" size="normal" @click="handleError">
            Go Home
          </POButton>
        </div>
      </div>
    </div>
    <Footer />
    <CookieBanner />
  </div>
</template>
