import { default as _91slug_930gzZTeqskAMeta } from "/usr/app/base/pages/[slug].vue?macro=true";
import { default as indexfRqBrWK8V6Meta } from "/usr/app/base/pages/index.vue?macro=true";
import { default as _91slug_93J59NWhiaHLMeta } from "/usr/app/base/pages/news/[slug].vue?macro=true";
import { default as _91slug_933mpFz2rBqBMeta } from "/usr/app/base/pages/team/[slug].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/usr/app/base/pages/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/app/base/pages/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/usr/app/base/pages/news/[slug].vue")
  },
  {
    name: "team-slug",
    path: "/team/:slug()",
    component: () => import("/usr/app/base/pages/team/[slug].vue")
  }
]