<template>
  <div class="relative flex h-[50px] w-full rounded-lg border-2 border-solid border-black" :style="`background: ${modelValue}; color: ${getTextColor(modelValue)}`">
    <input
      :id="uid"
      v-model="val"
      :name="uid"
      class="invisible size-0"
      type="color"
      width="0"
      height="0"
      @change="updateColors(colorNames, $event)"
    >
    <label class="absolute top-0 flex size-full items-center justify-center" :for="uid">
      <slot />
    </label>
  </div>
</template>

<script setup lang="ts">
import { hexToRgb } from '~/utils/color'

interface Props {
  modelValue: string
  uid: string;
  colorNames: string[]
}
const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: '#fff',
    uid: 'uid'
  }
)
const emit = defineEmits(['update:modelValue'])
const val = computed({
  get () {
    return props.modelValue
  },
  set (val) {
    emit('update:modelValue', val)
  }
})
function convertHexToRGBVar (hexString: string) {
  if (hexString === '#ffffff') { return '255 255 255' }
  return '0 0 0'
}

function updateColors (names: string[], event: Event) {
  const color = (event.target as HTMLInputElement).value
  const palette = getPalette(color)
  const shadeValues = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '800']
  function setTextColorForCurrentColor (varName: string, paletteColor: string) {
    document.body.style.setProperty(`--${varName}-text`, convertHexToRGBVar(getTextColor(paletteColor)))
  }
  names.forEach((name) => {
    document.body.style.setProperty(`--${name}`, hexToRgb(color))
    setTextColorForCurrentColor(name, hexToRgb(color) ?? '0 0 0')
    shadeValues.forEach((shadeValue: string, index: number) => {
      const cssVarName = `${name}-${shadeValue}`
      const paletteColor = palette[index]
      document.body.style.setProperty(`--${cssVarName}`, paletteColor)
      setTextColorForCurrentColor(cssVarName, paletteColor)
    })
  })
  const storedColors = { cssvars: getCssVars()?.split(';\n').join(';') }
  setItem('cssvars', storedColors)
}
</script>
