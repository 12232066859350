<template>
  <footer
    class="bg-background-800 text-white"
  >
    <div class="md:py-12">
      <div class="max-wrapper footer font-main flex flex-row flex-wrap text-base md:gap-x-4 md:gap-y-8 lg:flex-nowrap lg:gap-8">
        <FooterInfo class="footerSection" :info-content="footer?.content" :social-links="footer?.socialLinks ?? []" :badges="footer?.badges ?? []" />
        <FooterNav class="footerSection lg:ml-1/12" :nav-items="footer?.navItems ?? []" />
        <FooterContactUs class="footerSection" :contact-us="footer?.contactUs ?? []" />
        <FooterLogo class="basis-full pt-6 md:basis-[calc(50%-0.5rem)] md:pt-0 lg:basis-1/6 lg:text-right" :logo="footer?.logo ?? ''" :back-to-top="footer?.backToTop ?? true" />
      </div>
    </div>
    <FooterCopyright
      class="border-divided bg-darker border-t text-white"
      :logo="copyright?.logo ?? ''"
      :legal-items="copyright?.legalItems ?? []"
    />
  </footer>
</template>
<script setup lang="ts">
import { blockContent } from '../component_querys'

interface NavItem {
  label: string;
  to: string;
}
interface ContactUs {
  address: BlockContent;
  phone: string;
  email: string;
}
interface Footer {
  content: BlockContent;
  socialLinks: {
    linkedIn: string;
    twitter: string;
    facebook: string;
    instagram: string;
  }
  badges: {url: string; alt: string;}[]
  navItems: NavItem[];
  contactUs: ContactUs;
  logo: string;
  backToTop: boolean;
}
interface Copyright {
  legalItems: NavItem[];
  logo: string;
}
const query = groq`*[_type == "footer"][0] {
  'footer': {
    ...,
    content[] {
      ${blockContent}
    },
    'socialLinks': socialLinks {
      'linkedIn': linkedIn,
      'twitter': twitter,
      'facebook': facebook,
      'instagram': instagram
    },
    badges[] {
      'url': asset->url + '?auto=format',
      'alt': alt
    },
    'contactUs': {
      address[] {
        ${blockContent}
      },
      'phone': phone,
      'email': email,
    },
    defined(logo) => {
      'logo': logo.asset->url,
      'backToTop': backToTop
    },
    'navItems': navItems[]->{
      'label': title,
      'to': select(
        slug.current == '/' => slug.current,
        slug.current != '/' => '/'+slug.current,
      ),
    },
  },
  'copyright': copyright {
    ...,
    defined(logo) => {
      'logo': logo.asset->url
    },
    'legalItems': legalItems[]->{
      'label': title,
      'to': select(
        slug.current == '/' => slug.current,
        slug.current != '/' => '/'+slug.current,
      ),
    },
  }
}`
const { data, error } = await useAsyncData(async () => {
  return await useSanity().fetch(query)
})
if (!data.value?.footer || error.value) { throw createError({ statusCode: 404, fatal: true, message: 'no footer settings' }) }

const footer = data.value?.footer as Footer

const copyright = data.value?.copyright as Copyright
</script>

<style lang="scss" scoped>
.max-wrapper {
  @apply py-8 px-4 sm:px-6;
}
.footerSection {
  @apply basis-full pt-6 md:basis-[calc(50%-0.5rem)] md:pt-0 lg:basis-1/4
}
</style>
